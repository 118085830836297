<template src="./complete.html"></template>
<style scoped src="./complete.css"></style>
<script>
import TransactionService from '@/services/TransactionService.vue'
import Loading from 'vue-loading-overlay'
import { required } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'
import moment from 'moment'

export default {
    components : {
       Loading, modal, pagination
    },
    data () {
        return {
            customerEmail:null,
            referenceNumber: null,
            transactionId:null,
            transactionStatus:'35',
            transactionType : '5',
            loaderVisible:false,
            transactionSummary : [],
            searchValue: '',
            searchBy:'0',
            errorMessages: applicationConstants.formsMessages,
            columns :['DateTime','ParentReferenceNumber','TransactionId','SenderName','SourceCurrency','SenderEmail','RecipientName','Amount','Action'],
            options :{
                headings : {
                    ParentReferenceNumber :'Parent Reference Number',
                    TransactionId: 'Transaction ID',
                    SenderName: 'Sender Name' ,
                    SourceCurrency:'Source Currency',
                    SenderEmail:'Sender Email',
                    RecipientName : 'Recipient Name',
                }
            },
            Utils: Utils,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: applicationConstants.enableLazyLoading
        }
    },
    methods : {
        async showTransactions() {
            this.$v.searchValue.$touch()
            if(this.$v.searchValue.$invalid){
                return
            }
            this.customerEmail = this.searchBy === '0' ? this.searchValue : null
            this.transactionId = this.searchBy === '2' ? this.searchValue : null
            this.referenceNumber = this.searchBy === '1' ? this.searchValue : null
            this.loaderVisible = true
            await this.getTransactionSummary()
            this.loaderVisible = false
        },
        async showAll() {
            this.$v.$reset()
            this.customerEmail=null
            this.referenceNumber=null
            this.transactionId=null
            this.searchValue=''
            this.loaderVisible = true
            await this.getTransactionSummary()
            this.loaderVisible = false
        },
        async getTransactionSummary() {
            try {
                await TransactionService.methods.GetTransactionSummary({
                    CustomerEmail : this.customerEmail,
                    ReferenceNumber : this.referenceNumber,
                    TransactionId : this.transactionId,
                    TransactionStatus : this.transactionStatus,
                    TransactionType : this.transactionType
                })
                .then(res => {
                    this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                    if (this.transactionSummary) {
                        this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                    } else {
                        this.transactionSummary = []
                    }
                    if (this.isLazyLoadingEnabled) {
                        this.$refs.paginationRef.selectedPage = 1
                    }
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            } catch (error) {
                //
            }
        },
        async transaction(row,type) {
            try {
                this.$bvModal.msgBoxConfirm('Are you sure you want to ' + type + ' the E-Transafer: '+row.TransactionId)
                .then(async value => {
                    if (value) {
                        this.loaderVisible = true
                        if(type ==='reject') {
                            await TransactionService.methods.CancelETransfer(row.SenderEmail,row.ReferenceNumber,row.TransactionId)
                            .then(() => {
                                this.transactionSummary = this.transactionSummary.filter(el => el.ReferenceNumber != row.ReferenceNumber)
                                this.showAlert(1, 'E-Transfer Cancelled Successfully')
                            })
                            .catch(err => {
                                this.alertObject = err
                                this.$bvModal.show(this.$refs.childModal.id)
                            })
                        }else if(type === 'complete') {
                            await this.completeETransfer(row)
                        }else if(type === 'review') {
                            this.$router.push({
                                name : 'ReviewETransfer',
                                params: {
                                   email : row.SenderEmail,
                                   Transactionid : row.TransactionId,
                                   type : 'completeetransfer',
                                   filters: {
                                        'searchBy': this.searchBy,
                                        'searchValue': this.searchValue
                                    }
                                }
                            })
                        }
                        this.loaderVisible = false
                    }               
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)    
                }) 
                this.loaderVisible = false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async completeETransfer(row) {
            await TransactionService.methods.CompleteETransfer({
                CustomerEmail :row.SenderEmail,
                ReferenceNumber:row.ReferenceNumber,
                TransactionId:row.TransactionId,
                TransactionTotal:row.Amount
            })
            .then(async res => {
                if(res.csEtransferCompleteResponse.SendMoneyRequest && Object.keys(res.csEtransferCompleteResponse.SendMoneyRequest).length > 0){
                    if(res.csEtransferCompleteResponse.PromoCodeData?.Code){
                        if(res.csEtransferCompleteResponse.PromoCodeData.Code && res.csEtransferCompleteResponse.PromoCodeData.Issue ) {
                            await TransactionService.methods.RedeemPromoCode({
                                CustomerEmail : row.SenderEmail,
                                Code : res.csEtransferCompleteResponse.PromoCodeData.Code._text,
                                IssueNumber : res.csEtransferCompleteResponse.PromoCodeData.Issue._text,
                                ValidateOnly : 'false',
                                ParentReferenceNumber : res.csEtransferCompleteResponse.ParentReferenceNumber._text,
                                RemittanceGateway : res.csEtransferCompleteResponse.PromoCodeData.RemittanceGateway._text,
                                Amount : res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text
                            })
                            .then(async () => {
                                await this.sendMoney(res.csEtransferCompleteResponse.SendMoneyRequest.OriginCurrency._text,
                                res.csEtransferCompleteResponse.SendMoneyRequest.RecipientId._text,
                                res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text,
                                row.SenderEmail,
                                res.csEtransferCompleteResponse.SendMoneyRequest.SessionId._text,
                                res.csEtransferCompleteResponse.SendMoneyRequest.Purpose._text,
                                row.ReferenceNumber,
                                res.csEtransferCompleteResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                            })
                            .catch(err => {
                                this.alertObject = err
                                this.$bvModal.show(this.$refs.childModal.id)    
                            })
                        }else{
                            await this.sendMoney(res.csEtransferCompleteResponse.SendMoneyRequest.OriginCurrency._text,
                            res.csEtransferCompleteResponse.SendMoneyRequest.RecipientId._text,
                            res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text,
                            row.SenderEmail,res.csEtransferCompleteResponse.SendMoneyRequest.SessionId._text
                            ,res.csEtransferCompleteResponse.SendMoneyRequest.Purpose._text,
                            row.ReferenceNumber,
                            res.csEtransferCompleteResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                        }
                    }else {
                        await this.sendMoney(res.csEtransferCompleteResponse.SendMoneyRequest.OriginCurrency._text,
                        res.csEtransferCompleteResponse.SendMoneyRequest.RecipientId._text,
                        res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text,
                        row.SenderEmail,res.csEtransferCompleteResponse.SendMoneyRequest.SessionId._text,
                        res.csEtransferCompleteResponse.SendMoneyRequest.Purpose._text,
                        row.ReferenceNumber, 
                        res.csEtransferCompleteResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                    }
                }else{
                    this.showAlert(null, 'Invalid Transaction Details. Not able to process E-Transfer Compliance.')
                    return
                }
            })  
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
            this.transactionSummary = this.transactionSummary.filter(el => el.ReferenceNumber != row.ReferenceNumber)
        },
        async sendMoney(originCurrency,recipientId,xferAmount,customerEmail,mrmssession,purpose,parentReferenceNumber,requestedDestinationAmount){
            if(this.isDisabledBank(customerEmail,recipientId)){
                this.showAlert(null, "Recipient's bank are temporarily disabled. The bank's system is under maintenance.")
                return
            }
            await TransactionService.methods.SendMoney({
                OriginCurrency : originCurrency,
                RecipientId : recipientId,
                XferAmount : xferAmount,
                CustomerEmail : customerEmail,
                Purpose : purpose,
                ParentReferenceNumber : parentReferenceNumber,
                SessionId : mrmssession,
                RequestedDestinationAmount : requestedDestinationAmount
            })
            .then(res => {
               this.$router.push({
                   name : 'Confirmation',
                   params : {
                       resSendMoney : res.csSendMoneyResponse.TransactionData,
                       parentReferenceNumber : res.csSendMoneyResponse.ParentReferenceNumber
                   }
               })
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)    
                this.loaderVisible = false
            })
        },
        isDisabledBank(customerEmail,recipientId){
            try {
                let recipientInfo = this.recipientList.find(el=> el.Id === recipientId)
                if(recipientInfo){
                    if(recipientInfo.RemittanceGatewayId === '2'){
                        let customProperties = recipientInfo.CustomProperties
                        customProperties = this.responseMapping(customProperties)
                        let bankCode = customProperties.find(el=> el.Name === 'bankCode' )
                        if(bankCode){
                            //check with disabled bank codes list if it contain return true
                        }
                    }
                }
                return false
            } catch (error) {
               //
            }
        },
        responseMapping(data){
            if(data) {
                data = Array.isArray(data) ? data : [data]
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text
                    })
                }
                data.map(reciptient)
            }else{
                data = []
            }   
            return data
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed: {
        transactionSummaryTable() {
            let transactionSummary = []
            if (this.transactionSummary && this.transactionSummary.length > 0) {
                transactionSummary = JSON.parse(JSON.stringify(this.transactionSummary))
                if (this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    transactionSummary = transactionSummary.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                transactionSummary = this.responseMapping(transactionSummary)
                transactionSummary.forEach(transaction => {
                    transaction['DateTime'] = Utils.methods.UTCtoLocal(moment(moment(transaction.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss'))
                })
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            this.isLazyLoadingEnabled ? updateTotalRecords() : null
            return transactionSummary
        }
    },
    async mounted () {
        if(this.$route.params.filters) {
            this.searchBy = this.$route.params.filters?.searchBy
            this.searchValue = this.$route.params.filters?.searchValue
            this.searchValue.length > 0 ? this.showTransactions() : this.showAll() 
        }
    },
    validations : {
        searchValue : { required }
    }
}
</script>
